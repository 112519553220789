// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-redirect-to-content-manager-index-js": () => import("./../../../src/components/RedirectToContentManager/index.js" /* webpackChunkName: "component---src-components-redirect-to-content-manager-index-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-demo-js": () => import("./../../../src/pages/demo.js" /* webpackChunkName: "component---src-pages-demo-js" */),
  "component---src-pages-demodev-demo-flight-attendant-portal-js": () => import("./../../../src/pages/demodev/demo-flight-attendant-portal.js" /* webpackChunkName: "component---src-pages-demodev-demo-flight-attendant-portal-js" */),
  "component---src-pages-demodev-demo-maintenance-portal-js": () => import("./../../../src/pages/demodev/demo-maintenance-portal.js" /* webpackChunkName: "component---src-pages-demodev-demo-maintenance-portal-js" */),
  "component---src-pages-demodev-demo-mygoji-js": () => import("./../../../src/pages/demodev/demo-mygoji.js" /* webpackChunkName: "component---src-pages-demodev-demo-mygoji-js" */),
  "component---src-pages-demodev-demo-passenger-portal-js": () => import("./../../../src/pages/demodev/demo-passenger-portal.js" /* webpackChunkName: "component---src-pages-demodev-demo-passenger-portal-js" */),
  "component---src-pages-demodev-js": () => import("./../../../src/pages/demodev.js" /* webpackChunkName: "component---src-pages-demodev-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-get-in-touch-js": () => import("./../../../src/pages/get-in-touch.js" /* webpackChunkName: "component---src-pages-get-in-touch-js" */),
  "component---src-pages-goji-for-trains-dev-js": () => import("./../../../src/pages/goji_for_trains_dev.js" /* webpackChunkName: "component---src-pages-goji-for-trains-dev-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portals-flight-attendant-portal-js": () => import("./../../../src/pages/portals/flight-attendant-portal.js" /* webpackChunkName: "component---src-pages-portals-flight-attendant-portal-js" */)
}

